// The following is generated by generate-wiki
// DO NOT modify this file
// Run `npm run generate-wiki` to update this file
// ------------------------------------------

import i18n from "i18next";

import type { ModelDefinition } from "@/__main__/data-model.mjs";
import createModel, { Mapped } from "@/__main__/data-model.mjs";
import getData, { readData } from "@/__main__/get-data.mjs";
import { appURLs } from "@/app/app-urls.mjs";
import { DATA_VERSION } from "@/game-fortnite/constants/wiki-data.mjs";
import { wikiData } from "@/game-fortnite/models/model-wiki.mjs";
import { devWarn } from "@/util/dev.mjs";
import { getLocale } from "@/util/i18n-helper.mjs";

const localizationModel = createModel(
  Mapped({ key: String, value: String }) satisfies ModelDefinition,
);

const locales = ["en"];
type WikiData = keyof typeof wikiData;
const dataNames = Object.keys(wikiData);
const API = [...dataNames, ...locales].reduce(
  (acc, name) => {
    acc[name] = `${appURLs.UTILS_STATIC}/fortnite/${DATA_VERSION}/${name}`;
    return acc;
  },
  {} as Record<WikiData, unknown>,
);

export default async function fetchData(
  disable: Partial<Record<WikiData, boolean>> = {},
) {
  const requiredPromises = [];

  for (const dataName of dataNames) {
    if (!disable[dataName])
      requiredPromises.push(
        getData(
          API[dataName],
          wikiData[dataName],
          ["fortnite", "wiki", dataName, DATA_VERSION],
          {
            shouldFetchIfPathExists: false,
          },
        ),
      );
  }

  const currentLocale = getLocale();
  if (!i18n.getResourceBundle(currentLocale, "gen-fortnite")) {
    if (API[currentLocale]) {
      const dataPath = [
        "fortnite",
        "wiki",
        "i18n",
        `${DATA_VERSION}-${currentLocale}`,
      ];

      let bundle = await readData(dataPath);
      if (!bundle)
        bundle = await getData(
          API[currentLocale],
          // @ts-ignore
          localizationModel,
          dataPath,
          {
            shouldSkipDBRead: true,
            shouldFetchIfPathExists: false,
          },
        );

      i18n.addResourceBundle(currentLocale, "gen-fortnite", bundle);
      await i18n.changeLanguage(currentLocale);
    } else
      devWarn(
        `fortnite localization does not have translations for locale: '${currentLocale}'`,
      );
  }

  return Promise.all(requiredPromises);
}
